export const FirebaseConfig = {
	"projectId": "mydel-97e2e",
	"appId": "1:5495034226:web:3356ca42644068e2490deb",
	"databaseURL": "https://mydel-97e2e-default-rtdb.asia-southeast1.firebasedatabase.app",
	"storageBucket": "mydel-97e2e.appspot.com",
	"locationId": "asia-northeast2",
	"apiKey": "AIzaSyD6seynsVRoa3l4cAvkOUHLjsXLytBq7iI",
	"authDomain": "mydel-97e2e.firebaseapp.com",
	"messagingSenderId": "5495034226",
	"measurementId": "G-LEH0R6RQLY"
};